import React from 'react'
import {
    Box,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Menu,
    MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    useMediaQuery, useTheme
} from '@mui/material'
import LinkIcon from '@mui/icons-material/Link';

interface IFicData {
    img: string,
    title: string,
    author: string,
    shortDescription: string|null,
    rows: number,
    cols: number,
    featured: boolean,
    mirrors: {
        ao3: string|null,
        rr: string|null,
        sb: string|null,
        sv: string|null,
        qq: string|null,
        reddit: string|null,
        fimfiction: string|null,
        scribblehub: string|null,
        webnovel: string|null,
    }
}

const Authors: React.FC = () => {

    const theme = useTheme();
    const matchDown800 = useMediaQuery(theme.breakpoints.down(800));
    const matchDown1200 = useMediaQuery(theme.breakpoints.down(1200));
    const matchDown1600 = useMediaQuery(theme.breakpoints.down(1600));
    const matchDown2000 = useMediaQuery(theme.breakpoints.down(2000));

    let cols;
    if(matchDown800){
        cols = 1;
    }else if(matchDown1200){
        cols = 2;
    }else if(matchDown1600){
        cols = 3;
    }else if(matchDown2000){
        cols = 4;
    }else{
        cols = 5;
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [currentFic, setCurrentFic] = React.useState<null | IFicData>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, itemData: IFicData) => {
        setAnchorEl(event.currentTarget);
        setCurrentFic(itemData);
    };
    const handleClose = (url:null|string) => {
        setAnchorEl(null);
        if(url) {
            window.open(url, "_blank", "noreferrer");
        }
    };

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <ImageList cols={cols}>
                {itemData.map((item, index) => {
                    const imgSrc = item.img;

                    if(imgSrc.indexOf("dummyimage") > -1){
                        return "";
                    } else {
                        return (
                            <ImageListItem key={index}>
                                <img
                                    src={`${imgSrc}`}
                                    style={{ width: 400, height: 600 }}
                                    // src={`${item.img}?w=248&fit=crop&auto=format`}
                                    // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    title={item.title}
                                    subtitle={item.author}
                                    actionIcon={
                                        <IconButton
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            aria-label={`info about ${item.title}`}
                                            onClick={(event) => handleClick(event, item)}
                                        >
                                            <LinkIcon />
                                        </IconButton>
                                    }
                                />
                            </ImageListItem>
                        );
                    }
                })}
            </ImageList>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                disableScrollLock={true}
                onClose={() => handleClose(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {currentFic?.mirrors.ao3 ? <MenuItem onClick={() => handleClose(currentFic.mirrors.ao3)}>Read on Archive of Our Own</MenuItem> : null}
                {currentFic?.mirrors.rr ? <MenuItem onClick={() => handleClose(currentFic.mirrors.rr)}>Read on Royal Road</MenuItem> : null}
                {currentFic?.mirrors.sb ? <MenuItem onClick={() => handleClose(currentFic.mirrors.sb)}>Read on SpaceBattles</MenuItem> : null}
                {currentFic?.mirrors.sv ? <MenuItem onClick={() => handleClose(currentFic.mirrors.sv)}>Read on Sufficient Velocity</MenuItem> : null}
                {currentFic?.mirrors.qq ? <MenuItem onClick={() => handleClose(currentFic.mirrors.qq)}>Read on Questionable Questing</MenuItem> : null}
                {currentFic?.mirrors.reddit ? <MenuItem onClick={() => handleClose(currentFic.mirrors.reddit)}>Read on Reddit</MenuItem> : null}
                {currentFic?.mirrors.fimfiction ? <MenuItem onClick={() => handleClose(currentFic.mirrors.fimfiction)}>Read on FIMFiction</MenuItem> : null}
                {currentFic?.mirrors.scribblehub ? <MenuItem onClick={() => handleClose(currentFic.mirrors.scribblehub)}>Read on ScribbleHub</MenuItem> : null}
                {currentFic?.mirrors.webnovel ? <MenuItem onClick={() => handleClose(currentFic.mirrors.webnovel)}>Read on WebNovel</MenuItem> : null}
            </Menu>
            <Box
                sx={{
                    width: '100%', // Box should take full width
                    display: 'flex', // Enable flex
                    flexDirection: 'column', // Horizontal layout
                    justifyContent: 'center', // Center on X-axis (because flexDirection is column)
                    alignItems: 'center', // Center on Y-axis
                    '& > :not(style)': {
                        m: 1,
                        width: {
                            xs: '100%', // 100% below breakpoint sm
                            sm: '60%', // 60% from breakpoint sm to md
                            md: '40%', // 40% from breakpoint md and up
                        },
                    },
                }}
            >
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Title</TableCell>
                                <TableCell align="left">Author</TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {itemData.map((item, index) => {
                                const imgSrc = item.img;
                                if (imgSrc.indexOf("dummyimage") > -1) {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {item.title}
                                            </TableCell>
                                            <TableCell align="left">{item.author}</TableCell>
                                            <TableCell align="left">
                                                <IconButton
                                                    aria-label={`info about ${item.title}`}
                                                    onClick={(event) => handleClick(event, item)}
                                                >
                                                    <LinkIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}

export default Authors

const itemData = [
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/63486-amaterasus-marvelous-dawn.jpg',
        title: "Amaterasu's Marvelous Dawn",
        author: '@InfamousVenous',
        shortDescription: "Okami and Marvel Cinematic Universe Crossover",
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/44346010/chapters/111525667",
            rr: "https://www.royalroad.com/fiction/63486/amaterasus-new-game",
            sb: "https://forums.spacebattles.com/threads/amaterasus-new-game.1083747/",
            sv: "https://forums.sufficientvelocity.com/threads/amaterasus-new-game.116007/",
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/65893-pokemon-mystery-dungeon-heroes-of-the-past.jpg',
        title: "PMD: Heroes of the Past",
        author: '@InfamousVenous',
        shortDescription: "Mystery Dungeon: Explorers of Sky 30 years post game. Redemption and found family fic.",
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/45880840/chapters/115472656",
            rr: "https://www.royalroad.com/fiction/65893/pokemon-mystery-dungeon-heroes-of-the-past",
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/remnants-of-hisui-pokemon-fiction-69490.jpg',
        title: "Remnants of Hisui",
        author: '@boneman_',
        shortDescription: "Remnants of Hisui",
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: "https://www.royalroad.com/fiction/63276/remnants-of-hisui-pokemon-fiction",
            sb: "https://forums.spacebattles.com/threads/remnants-of-hisui-pok%C3%A9mon-fiction.1067312/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "Shine: Mass Effect AI/SI",
        author: '@Cammy of Deer',
        shortDescription: "Shine: Mass Effect AI/SI",
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/33441295",
            rr: "https://www.royalroad.com/fiction/43534/shine-mass-effect-ai-si",
            sb: "https://forums.spacebattles.com/threads/shine-mass-effect-ai-si.785758/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/43533-wolfswood-dark-souls-si-sif.jpg',
        title: "Wolfswood: Dark Souls Sif!SI",
        author: '@Cammy of Deer',
        shortDescription: "Wolfswood: Dark Souls Sif!SI",
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/33571729",
            rr: "https://www.royalroad.com/fiction/43533/wolfswood-dark-souls-si-sif",
            sb: "https://forums.spacebattles.com/threads/wolfswood-dark-souls-si-sif.827893/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/67553-ungilded-dark-type-specialist-pokemon-si.jpg',
        title: "Ungilded: Pokemon Dark-type specialist SI",
        author: '@Cammy of Deer',
        shortDescription: "Ungilded: Pokemon Dark-type specialist SI",
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: "https://www.royalroad.com/fiction/67553/ungilded-dark-type-specialist-pokemon-si",
            sb: "https://forums.spacebattles.com/threads/ungilded-dark-type-specialist-pokemon-si.1087344/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/61228-i-will-touch-the-skies-a-pokemon-fanfiction.jpg',
        title: "I Will Touch The Skies",
        author: '@Soussouni',
        shortDescription: "'Nobledark' Sinnoh journey fic through the eyes of an OC-focused cast.",
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: "https://www.royalroad.com/fiction/61228/i-will-touch-the-skies-a-pokemon-fanfiction",
            sb: "https://forums.spacebattles.com/threads/i-will-touch-the-skies-a-pokemon-fanfiction.1057023/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/march-hare-a-pokemon-fanfiction-aacaqlqy8xi.jpg',
        title: "March Hare",
        author: '@Keithwasabi',
        shortDescription: "A Buneary's journey to elevate Pokémon from their status as 'smart pets' into humanity's equals.",
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: "https://www.royalroad.com/fiction/64926/march-hare-a-pokemon-fanfiction",
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/32897-hyphen-pokemon-emerald.jpg',
        title: "Hyphen (Pokemon Emerald)",
        author: '@Dermonster',
        shortDescription: "Leaving the safety of her hidden commune, a Ralts narrowly avoids capture on Hoenn Route 102.",
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/3881578",
            rr: "https://www.royalroad.com/fiction/32897/hyphen-pokemon-emerald",
            sb: "https://forums.spacebattles.com/threads/hyphen-pokemon-emerald.312731/",
            sv: "https://forums.sufficientvelocity.com/threads/hyphen-pokemon-emerald.9539/",
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "What Little Good",
        author: '@incog9012',
        shortDescription: "A lone Lucario finds himself facing down this coming end, and though he cannot save many... he can save a few alongside himself.",
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/46866934",
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/57741-pokemon-trainer-vicky-pokemon-si.jpg',
        title: "Pokemon Trainer Vicky",
        author: '@Seras',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/41084778/chapters/102977655",
            rr: "https://www.royalroad.com/fiction/57741/pokemon-trainer-vicky-pokemon-si",
            sb: "https://forums.spacebattles.com/threads/pokemon-trainer-vicky-pokemon-si.1028636/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "The Friends Made Along the Way",
        author: '@Gloweye',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: null,
            sb: "https://forums.spacebattles.com/threads/the-friends-made-along-the-way.1125260/",
            sv: null,
            qq: "https://forum.questionablequesting.com/threads/the-friends-made-along-the-way-pokemon-au.24523/",
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "Making a Difference: Repairing Hisui",
        author: '@DaBlizz',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/45615592/chapters/114782056",
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/75058-the-indigo-war-oc-pokemon-prequel.jpg',
        title: "The Indigo War",
        author: '@Violet Moon',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: "https://www.royalroad.com/fiction/75058/the-indigo-war-oc-pokemon-prequel",
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/73681-pokemon-source.jpg',
        title: "Pokémon: Source",
        author: '@Mleman1946',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/46812772/chapters/117912271",
            rr: "https://www.royalroad.com/fiction/73681/pokemon-source",
            sb: "https://forums.spacebattles.com/threads/pokémon-source.1113169/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "The Pirate and The Prisoner",
        author: '@Papyroo',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: "https://www.reddit.com/r/NatureofPredators/comments/15hhi00/the_pirate_and_the_prisoner_a_nature_of_predators/?share_id=ororbn51-fpRGTIDYiaxJ",
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/unbroken-disguise-aacaany42xm.jpg',
        title: "Unbroken Disguise",
        author: '@WalletzFailz',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/48715681/chapters/122887729",
            rr: "https://www.royalroad.com/fiction/73258/unbroken-disguise",
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "Sky Piercer",
        author: '@Regal Eagle',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/48609967/chapters/122614750",
            rr: null,
            sb: "https://forums.spacebattles.com/threads/sky-piercer-a-monster-hunter-oc-story.1104052/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "The Devil is in the Details",
        author: '@Pizzaplate',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/27594497/chapters/67505069",
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: '/assets/the-long-and-short-of-it-tmp-cover.png',
        title: "The Long And Short Of It",
        author: '@BobMcBobinstine / @Bobbles',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: "https://www.fimfiction.net/story/501001/the-long-and-short-of-it",
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: '/assets/everyday-life-with-guardsmares.png',
        title: "Everyday Life With Guardsmares",
        author: '@TheManFromAnotherTime / @Bobbles',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: "https://www.fimfiction.net/story/479815/everyday-life-with-guardsmares",
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/66484-i-will-strive-beyond-this-cursed-bloodline.jpg',
        title: "I will strive beyond this cursed bloodline!",
        author: '@Katsu',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: "https://www.royalroad.com/fiction/66484/i-will-strive-beyond-this-cursed-bloodline",
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/77141-gregory-johnsons-johto-adventure.jpg',
        title: "Gregory Johnson's Johto Adventure",
        author: '@Bennyandthejets',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: "https://www.royalroad.com/fiction/77141/gregory-johnsons-johto-adventure/",
            sb: "https://forums.spacebattles.com/threads/gregory-johnsons-johto-adventure.1127426/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: '/assets/pmd-unchained-explorers.png',
        title: "PMD: Unchained Explorers",
        author: '@OmegaEntertainment',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/52012816/chapters/131533117",
            rr: null,
            sb: null,
            sv: null,
            qq: "https://forum.questionablequesting.com/threads/pok%C3%A9mon-mystery-dungeon-unchained-explorers-pok%C3%A9mon-mystery-dungeon-explorers-of-sky.16477/",
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "Digimon Generations",
        author: '@ponky kong',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/51807622/chapters/130980217",
            rr: "https://www.royalroad.com/fiction/77563/digimon-generations",
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "Wasteland Waves",
        author: '@Le Edgelord',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/51996895/chapters/131490430",
            rr: null,
            sb: "https://forums.spacebattles.com/threads/wasteland-waves-fallout-new-vegas-azur-lane.1131013/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "The Wraith of Palewood",
        author: '@Gengar of Bonk',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/52123708/chapters/131833687",
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "Hollow Knight: Goldheart Edition",
        author: '@KIWI',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/47878216/chapters/120706198",
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "Into The Wild Pale Yonder",
        author: '@Randal the Vandal',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: "https://www.royalroad.com/fiction/78326/into-the-wild-pale-yonder",
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "Citrine Isles",
        author: '@Diatomic Rex',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/45299356/chapters/113968096",
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "Eh, It's Close Enough to a Rabbit That I'm Willing to Have One as a Substitute, Although Don't Assume That Means I'll Kill Over It. I Mean, I Will, But It's Gonna Come Back Anyways, Right? It's Not That Big of a Deal",
        author: '@sauoce',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/49186102/chapters/124106119",
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "BoC Omake: The Longest Time",
        author: '@blobbycat',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: null,
            sb: "https://forums.spacebattles.com/threads/borne-of-caution-pokemon-isekai.849292/page-200#post-86845380",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/79126-pokeflicks-and-hollywood-hits.jpg',
        title: "PokéFlicks and Hollywood Hits",
        author: '@Garmfild',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/52899853/chapters/133808143",
            rr: "https://www.royalroad.com/fiction/79126/pokeflicks-and-hollywood-hits",
            sb: "https://forums.spacebattles.com/threads/pok%C3%A9flicks-and-hollywood-hits-pok%C3%A9mon-black-2-white-2-oc.1142785/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "A Legendary Week!",
        author: '@ColsonOtis',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/52639801/chapters/133150972",
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: "https://www.webnovel.com/book/a-legendary-week!_28384820800830505/introduction_76194977775928454",
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/59385-catching-a-conquering-landscape-pokemon.jpg',
        title: "Catching a Conquering Landscape",
        author: '@exceedes',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: "https://www.royalroad.com/fiction/59385/catching-a-conquering-landscape-pokemon-oc",
            sb: "https://forums.spacebattles.com/threads/catching-a-conquering-landscape-pokemon-journey-oc.1138189/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://www.royalroadcdn.com/public/covers-large/79494-rising-flames-and-surging-waves.jpg',
        title: "Rising Flames and Surging Waves",
        author: '@Mxelawl',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: "https://www.royalroad.com/fiction/79494/unity",
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "Into The Dark",
        author: '@Erin',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/51313636/chapters/129654949",
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "To Be Seen",
        author: '@Railyn',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/53116372",
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: '/assets/a-certain-pokemon-shop.jpg',
        title: "A Certain Pokemon Shop",
        author: '@Lazy_Writer',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: "https://www.webnovel.com/book/a-certain-pokemon-shop(re-write)_26988719005970605",
        }
    },
    {
        img: '/assets/kamen-rider-singularity.jpg',
        title: "Kamen Rider: Singularity",
        author: '@Lazy_Writer',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: "https://www.webnovel.com/book/kamen-rider-singularity_26877731406843005",
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "That Metal Jingle",
        author: '@Eternal Luxray Cowboy',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/53091115",
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "(NSFW) - For The Love Of A Vixen",
        author: '@Forgothrax',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/52482691/chapters/132770689",
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "Law of Hisui",
        author: '@Baron von Richington',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/53647087/chapters/135803254",
            rr: null,
            sb: null,
            sv: "https://forums.sufficientvelocity.com/threads/law-of-hisui.127507/",
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: '/assets/stolen-lives-stolen-time.png',
        title: "Stolen Lives, Stolen Time",
        author: '@TopBat',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/53446699/chapters/135277282",
            rr: null,
            sb: "https://forums.spacebattles.com/threads/stolen-lives-stolen-time-pokemon-isekai.1142659/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: '/assets/good-evening.png',
        title: "Good Evening",
        author: '@Rinzoro',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: "https://www.fimfiction.net/story/515193/good-evening",
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "Borne of Wishes",
        author: '@awilddapperslimeappeared',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/53872660",
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "A Sun to Call his Own",
        author: '@awilddapperslimeappeared',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/55997824",
            rr: null,
            sb: null,
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: '/assets/within-our-nation-a-team-rocket-story.jpg',
        title: "Within Our Nation - A Team Rocket Story",
        author: '@FuzzyZergling',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: "https://www.royalroad.com/fiction/89012/within-our-nation-a-team-rocket-story",
            sb: "https://forums.spacebattles.com/threads/within-our-nation-a-team-rocket-story.1171191/",
            sv: "https://forums.sufficientvelocity.com/threads/within-our-nation-a-team-rocket-story.132189/",
            qq: "https://forum.questionablequesting.com/threads/within-our-nation-a-team-rocket-story.27812/",
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: '/assets/a-rangers-mission-a-lost-soul.png',
        title: "A Ranger's Mission: A Lost Soul",
        author: '@TheBlizWiz / @Thewilliamspot1',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: "https://archiveofourown.org/works/58408636",
            rr: "https://www.royalroad.com/fiction/92605/a-rangers-mission-a-lost-soul",
            sb: "https://forums.spacebattles.com/threads/a-rangers-mission-a-lost-soul-pok%C3%A9mon-oc-si.1182160/",
            sv: "https://forums.sufficientvelocity.com/threads/a-rangers-mission-a-lost-soul-pok%C3%A9mon-oc-si.134071/",
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "Shaded Silk",
        author: '@Fabhar',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: null,
            sb: "https://forums.spacebattles.com/threads/shaded-silk-pokemon-oc-isekai.1184962/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    {
        img: 'https://dummyimage.com/400x600/000000/fff.jpg&text=%3CNo+Cover+Art%3E',
        title: "Leavanny Alone",
        author: '@omnifox999',
        shortDescription: null,
        rows: 2,
        cols: 2,
        featured: true,
        mirrors: {
            ao3: null,
            rr: null,
            sb: "https://forums.spacebattles.com/threads/leavanny-alone-a-pokemon-oc-insert-thing.1185313/",
            sv: null,
            qq: null,
            reddit: null,
            fimfiction: null,
            scribblehub: null,
            webnovel: null,
        }
    },
    // {
    //     img: '',
    //     title: "",
    //     author: '@',
    //     shortDescription: null,
    //     rows: 2,
    //     cols: 2,
    //     featured: true,
    //     mirrors: {
    //         ao3: "",
    //         rr: "",
    //         sb: "",
    //         sv: "",
    //         qq: "",
    //         reddit: "",
    //         fimfiction: "",
    //         scribblehub: "",
    //         webnovel: "",
    //     }
    // },
]